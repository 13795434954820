<template>
  <div
    v-if="dataset"
    class="emails-dataset-view page-padding py-7"
  >
    <div class="emails-dataset-view__header mb-4">
      <div
        style="max-width: 600px"
      >
        <BackLink
          :text="$t('emailsDatasets.back_to_datasets')"
          @click="() => $router.push({ name: 'EmailsDatasetsView' })"
          show-text
        />
        <HeaderName
          :editable="false"
          :item="dataset"
          show-id
        />
        <ItemDescription
          :item="dataset"
          @save="updateDescription"
        />
      </div>
      <div class="emails-dataset-view__actions">
        <v-text-field
          v-model="searchedEmailsSubject"
          class="subject-filter-field"
          variant="outlined"
          color="primary"
          density="compact"
          :placeholder="$t('filter')"
          @input="onSubjectFilterInput"
        />
        <div class="add-emails-button__container">
          <ButtonWithIcon
            class="add-emails-button"
            icon="fas fa-plus"
            color="primary"
            variant="outlined"
            :message="$t('emailsDatasets.add_emails')"
            :disabled="isDatasetUploading"
            @click="() => $refs.uploader.click()"
            rounded
          />
        </div>
      </div>
    </div>
    <TableWithFooter
      :loading="loading || (isDatasetUploading && emails.length === 0)"
      :paginated-items-length="emails.length"
      :total="totalEmails"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
      @sort="onSort"
      sortable
    >
      <template #header>
        <v-col cols="6">
          {{ $t('emailsDatasets.email_subject') }}
        </v-col>
        <v-col cols="2">
          {{ $t('emailsDatasets.email_author') }}
        </v-col>
        <v-col cols="4">
          {{ $t('emailsDatasets.email_recipients') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="email in emails"
            :key="email.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="6">
              <ItemName
                :key="email.id"
                name-field="subject"
                class="inline-top"
                :clickable="false"
                :item="email"
              />
            </v-col>
            <v-col cols="2">
              <v-tooltip
                bottom
              >
                <template #activator="{ props }">
                  <span v-bind="props">
                    {{ getEmailAuthor(email) }}
                  </span>
                </template>
                {{ email.author.smtp_address }}
              </v-tooltip>
            </v-col>
            <v-col
              class="emails-receiver-cell"
              cols="4"
            >
              {{ getEmailReceivers(email) }}
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <FileInput
      ref="uploader"
      :supported-extensions="$options.constants.SUPPORTED_EXTENSIONS"
      @change="handleUploadClick"
    />
  </div>
</template>

<script>

import FileInput from '@/components/common/elements/Forms/FileInput.vue';
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter.vue';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import { EmailsDatasetsAPI } from '@/API/classify/EmailsDatasetsAPI';
import { uploadEmails, displaySnackbarError, displaySnackbarSuccess, SUPPORTED_EXTENSIONS } from './EmailUploadsHelper';
import HeaderName from '@/components/common/elements/General/HeaderName.vue';
import BackLink from '@/components/common/elements/Navigation/BackLink.vue';
import ItemDescription from '@/components/common/elements/General/ItemDescription.vue';
import ButtonWithIcon from '@/components/common/elements/Forms/ButtonWithIcon.vue';


export default {
  name: 'EmailsDatasetView',

  components: {
    ItemName,
    TableWithFooter,
    FileInput,
    HeaderName,
    BackLink,
    ItemDescription,
    ButtonWithIcon,
  },

  constants: {
    FETCH_DATASET_TIMEOUT: 2000,
    SUPPORTED_EXTENSIONS,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      emails: [],
      totalEmails: 0,
      itemsPerPage,
      currentPage,
      dataset: null,
      loading: false,
      descendingSort: true,
      searchedEmailsSubject: '',
    };
  },

  computed: {
    isDatasetUploading() {
      return this.dataset.status === 'uploading';
    },
  },

  watch: {
    itemsPerPage() {
      this.resetCurrentPage();
      this.fetchEmails();
    },

    currentPage() {
      this.fetchEmails();
    },
  },

  async created() {
    await this.fetchEmailsDataset();
    this.fetchEmails();
    this.initRecurrentDatasetFetching()
  },

  unmounted() {
    this.cancelRecurrentDatasetFetching();
  },

  methods: {
    async fetchEmailsDataset() {
      const response = await EmailsDatasetsAPI.get(this.datasetId);
      this.dataset = response.data;
    },

    async fetchEmails() {
      try {
        this.loading = true;
        const offset = (this.currentPage - 1) * this.itemsPerPage;
        const searchedEmailsSubject = this.searchedEmailsSubject.trim().toLowerCase();
        const response = await EmailsDatasetsAPI.fetchEmails(
          this.datasetId, offset, this.itemsPerPage, this.descendingSort, searchedEmailsSubject);
        this.emails = response.data;
        this.totalEmails = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        displaySnackbarError(this.$t('emailsDatasets.fetch_emails_error'));
      }
      this.loading = false;
    },

    resetCurrentPage() {
      this.currentPage = 1;
    },

    async handleUploadClick(files) {
      if (this.dataset.status === 'uploading') {
        displaySnackbarError(this.$t('emailsDatasets.dataset_in_uploading_state_error'));
        return;
      }
      try {
        await uploadEmails(this.dataset.id, files);
        await this.fetchEmailsDataset();
        this.initRecurrentDatasetFetching()
      } catch (error) {
        displaySnackbarError(this.$t('emailsDatasets.emails_upload_error', { detail: error.response.data.detail }));
      }
    },

    /* If a dataset is in an uploading status we need to recurrently
     * fetch this dataset until it have a different status than uploading. */
    initRecurrentDatasetFetching() {
      if (this.dataset.status === 'uploading') {
        this.recurrentDataseFetchingId = setInterval(async () => {
          await this.fetchEmailsDataset();
          if (this.dataset.status !== 'uploading') {
            this.cancelRecurrentDatasetFetching()
            await this.fetchEmails();
            displaySnackbarSuccess(this.$t('emailsDatasets.emails_upload_success'));
          }
        }, this.$options.constants.FETCH_DATASET_TIMEOUT);
      }
    },

    cancelRecurrentDatasetFetching() {
      clearInterval(this.recurrentDataseFetchingId);
      this.recurrentDataseFetchingId = null
    },

    getEmailAuthor(email) {
      return email.author.name || email.author.smtp_address;
    },

    getEmailReceivers(email) {
      const emailsReceivers = [];
      email.to.forEach((receiver) => {
        emailsReceivers.push(receiver.name || receiver.smtp_address);
      });

      return emailsReceivers.join(', ');
    },

    onSort(descendingSort) {
      this.descendingSort = descendingSort;
      this.fetchEmails();
    },

    async updateDescription(newDescription) {
      const response = await EmailsDatasetsAPI.update(this.datasetId, { description: newDescription });
      this.dataset = response.data;
    },

    async onSubjectFilterInput() {
      this.resetCurrentPage();
      await this.fetchEmails();
    },
  },

  props: {
    datasetId: {
      type: Number,
      required: true,
    },
  },

  emits: [],
}
</script>

<style lang="scss" scoped>

.emails-dataset-view__header {
  display: flex;
  gap: 30px;
  flex-direction: column;

  .emails-dataset-view__actions {
    display: flex;
    gap: 20px;

    .subject-filter-field {
      max-width: 300px;
    }

    .add-emails-button__container {
      max-height: 42px;
      display: flex;
      align-items: center
    }
  }
}

.emails-receiver-cell {
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
}

</style>
